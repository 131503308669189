
  import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
  import 'jodit/build/jodit.min.css'
  //@ts-ignore
  import { JoditEditor } from 'jodit-vue'
  import { API_URL } from '@/config'
  import { namespace } from 'vuex-class'
  import Cookies from 'js-cookie'
  import { forEach } from 'lodash'

  const tAuth = namespace('tAuth')

  @Component({ components: { JoditEditor } })
  export default class BaseEditor extends Vue {
    @Prop({ required: false, type: String, default: '' }) value!: string

    @tAuth.State('token') token!: string

    token2 = ''

    text = ''

    config = {
      height: 400,
      uploader: {
        url: `${API_URL}/media/upload`,
        format: 'json',
        headers: {
          Authorization: `Bearer ${this.token2}`
        },
        isSuccess: function(resp: any) {
          return !resp.error
        },
        getMessage: function(resp: any) {
          return resp.msg
        },
        filesVariableName: function() {
          return 'files'
        },
        process: function(resp: any) {
          return {
            files: resp.data || []
          }
        },
        defaultHandlerSuccess: function(data: any) {
          console.log(data)
          forEach(data.files, value => {
            //@ts-ignore
            // this.s.insertImage(`https://cdn.nguyenquangbach.com/${value.path}`, { maxWidth: '100%' })
            this.s.insertImage(`${value.path}`, { maxWidth: '100%' })
          })
        },
        error: function(e: any) {
          console.error(e)
        }
      }
    }

    @Watch('text') watchText(new_: string) {
      if (new_ !== '') {
        this.$emit('change', new_)
      }
    }

    setData(text: string) {
      this.text = text || ''
    }

    reset() {
      this.text = ''
    }

    created() {
      if (this.value && this.value !== '') {
        this.text = this.value
      }
      this.token2 = this.token || Cookies.get('access_token') || ''
      this.config.uploader.headers.Authorization = `Bearer ${this.token2}`
    }
  }
